
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { GET_DIMENSIONS } from "@/modules/catalog/dimensions/graphql/Queries";
import { CREATE_DIMENSION } from "@/modules/catalog/dimensions/graphql/Mutations";
import { AddDimensionForm } from "@/modules/catalog/dimensions/interfaces";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new dimension",
	components: {
		Input,
		// Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const addDimensionForm = ref<null | HTMLFormElement>(null);
		const measurements = ref([]) as Record<any, any>;

		// Given Add Dimension Form Interface
		const dimensionData = ref<AddDimensionForm>({
			name: "",
			measurement: "",
		});

		measurements.value = [
			{
				label: "message.GRAMS",
				value: "gm",
			},
			{
				label: "message.CENTIMETER",
				value: "cm",
			},
		];

		// Submit Handler Request
		const submitHandlerForm = async () => {
			addDimensionForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						name: dimensionData.value.name,
						measurement: dimensionData.value.measurement,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_DIMENSION,
						variables: { input: formData },
						update: (store, { data: { create_dimension } }) => {
							const pervious_record = store.readQuery({
								query: GET_DIMENSIONS,
								variables: {
									page: 1,
									limit: 10,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_DIMENSIONS,
								variables: {
									page: 1,
									limit: 10,
								},
								data: {
									dimensions: {
										...pervious_record.dimensions,
										data: [create_dimension, ...pervious_record.dimensions.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_ADDED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			addDimensionForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("addDimensionAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_dimension")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			dimensionData,
			addDimensionForm,
			measurements,
			submitHandlerForm,
		};
	},
});
