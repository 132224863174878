
import {setPageHeader} from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import AddDimension from "@/modules/catalog/dimensions/components/AddDimension.vue"
import EditDimension from "@/modules/catalog/dimensions/components/EditDimension.vue"
import DimensionsList from "@/modules/catalog/dimensions/components/DimensionsList.vue"

export default defineComponent({
	name: "dimension",
	components: {
        AddDimension,
        EditDimension,
		DimensionsList,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
                title: "message.DIMENSIONS",
                actionButton: {
                    ability: 'add_manufacturer',
                    pageAction: {
                        action: "addDimensionAction",
                    },
                    button: {
                        icon: "bi bi-plus",
                        openModal: true,
                    }
                },
                breadCrumbs:[
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.CATALOG", link: "#"},
                    { name: "message.DIMENSION"}
                ]
            });
		});
	},
});
