import { gql } from "graphql-tag";
import { DIMENSION_FIELDS } from "./Fragments";

export const CREATE_DIMENSION = gql`
	mutation CreateDimension($input: DimensionInput!) {
		create_dimension(dimension: $input) {
			...DimensionFields
		}
	}
	${DIMENSION_FIELDS}
`;

export const UPDATE_DIMENSION = gql`
	mutation UpdateDimension($input: DimensionInput!) {
		update_dimension(dimension: $input) {
			...DimensionFields
		}
	}
	${DIMENSION_FIELDS}
`;

export const DELETE_DIMENSION = gql`
	mutation DeleteDimension($id: Int!) {
		delete_dimension(id: $id) {
			...DimensionFields
		}
	}
	${DIMENSION_FIELDS}
`;

export default { CREATE_DIMENSION, UPDATE_DIMENSION, DELETE_DIMENSION };
