
import { defineComponent, ref, inject, watchEffect, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { GET_DIMENSIONS, SEARCH_DIMENSIONS } from "@/modules/catalog/dimensions/graphql/Queries";
import { DELETE_DIMENSION } from "@/modules/catalog/dimensions/graphql/Mutations";
import Search from "../../../../components/search/Search.vue";
import { useI18n } from "vue-i18n";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	name: "dimension list",
	components: { Search, Table, InnerLoader },
	setup() {
		const store = useStore();
		const dimensionData: any = ref([]);
		const pagination = ref({}) as Record<any, any>;
		const emitter: any = inject("emitter");
		const loader = ref(false);
		const loading = ref(false);
		const clickHandler = ref() as Record<any, any>;
		const currentPage = ref();
		const system_locale = ref();
		const i18n = useI18n();
		const permissions = store.getters.getPermissions;
		const activeIndex = ref(-1);
		const searchObservable: any = ref(null);
		const observable: any = ref(null);

		const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.MEASUREMENT",
				key: "measurement",
			},
			{
				label: "message.CREATED_AT",
				key: "created_at",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const dimensions = (page = 1, reload = false) => {
			if (searchObservable.value != null) {
				searchObservable.value?.unsubscribe();
				searchObservable.value = null;
			}
			if (page == 1) observable.value?.unsubscribe();
			loader.value = true;
			observable.value = Apollo.watchQuery({
				query: GET_DIMENSIONS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-and-network",
				errorPolicy: "all",
				variables: {
					page: page,
					limit: 10,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				reload ? (currentPage.value = 1) : false;
				clickHandler.value = dimensions;
				dimensionData.value = data.dimensions.data;
				pagination.value = data.dimensions;
				loader.value = false;
			});
		};

		const handleCurrentChange = (num: number) => {
			clickHandler.value(num);
		};

		const searchHandler = (page = 1) => {
			if (observable.value != null) {
				observable.value?.unsubscribe();
				observable.value = null;
			}
			searchObservable.value?.unsubscribe();
			const searchData = store.getters.getSearchData;
			loader.value = true;
			searchObservable.value = Apollo.watchQuery({
				query: SEARCH_DIMENSIONS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				nextFetchPolicy: "network-only",
				variables: {
					page: page,
					limit: 10,
					search_key: searchData.data.input,
					locale: system_locale.value,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}

				clickHandler.value = searchHandler;
				dimensionData.value = data.search_dimensions.data;
				pagination.value = data.search_dimensions;
				loader.value = false;
			});
		};

		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_DIMENSION,
						variables: { id: id },
						update: (store, { data: { delete_dimension } }) => {
							// const read_data = store.readQuery({
							// 	query: GET_DIMENSIONS,
							// 	variables: {
							// 		page: pagination.value.current_page,
							// 		limit: 10,
							// 	},
							// }) as Record<any, any>;
							// const data = read_data.dimensions.data.filter((t: any) => t.id !== delete_dimension.id);
							// store.writeQuery({
							// 	query: GET_DIMENSIONS,
							// 	data: {
							// 		dimensions: {
							// 			...read_data.dimensions,
							// 			data: [...data],
							// 		},
							// 	},
							// 	variables: {
							// 		page: pagination.value.current_page,
							// 		limit: 10,
							// 	},
							// });
							if (searchObservable.value != null) searchHandler(pagination.value.current_page);
							else dimensions(pagination.value.current_page);
							loader.value = false;
						},
					});
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		// load Dimensions
		dimensions();

		const handleEdit = (dimension: Record<any, any>) => {
			dimension = { ...dimension, current_page: pagination.value.current_page };
			emitter.emit("editDimensionAction", dimension);
		};

		onMounted(() => {
			document.addEventListener("click", handleClickOutside);
		});

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_DIMENSIONS,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					locale: system_locale.value,
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_dimensions?.data[0]?.path, "_blank");
					loading.value = false;
				}
				loading.value = false;
				sub.unsubscribe();
			});
		};

		onUnmounted(() => {
			observable.value?.unsubscribe();
			searchObservable.value?.unsubscribe();
		});

		return {
			pagination,
			dimensionData,
			clickHandler,
			currentPage,
			permissions,
			columns,
			loading,
			loader,
			exportFile,
			dimensions,
			searchHandler,
			handleCurrentChange,
			handleEdit,
			handleDelete,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
