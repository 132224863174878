import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DimensionsList = _resolveComponent("DimensionsList")!
  const _component_AddDimension = _resolveComponent("AddDimension")!
  const _component_EditDimension = _resolveComponent("EditDimension")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_DimensionsList)
    ]),
    _createVNode(_component_AddDimension),
    _createVNode(_component_EditDimension)
  ], 64))
}