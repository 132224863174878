
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { GET_DIMENSIONS } from "@/modules/catalog/dimensions/graphql/Queries";
import { UPDATE_DIMENSION } from "@/modules/catalog/dimensions/graphql/Mutations";
import { EditDimensionForm } from "@/modules/catalog/dimensions/interfaces";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new dimension",
	components: {
		Input,
		// Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const emitter: any = inject("emitter");
		const current_page = ref(1);
		const editDimensionForm = ref<null | HTMLFormElement>(null);
		const measurements = ref([]) as Record<any, any>;
		const i18n = useI18n();
		// Given Edit Dimension Form Interface
		const editDimensionData = ref<EditDimensionForm>({
			id: null,
			name: "",
			measurement: "",
		});

		measurements.value = [
			{
				label: "message.GRAMS",
				value: "gm",
			},
			{
				label: "message.CENTIMETER",
				value: "cm",
			},
		];
		// Submit handler Request
		const submitHandlerForm = async () => {
			editDimensionForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					const formData = {
						id: editDimensionData.value.id,
						name: editDimensionData.value.name,
						measurement: editDimensionData.value.measurement,
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_DIMENSION,
						variables: { input: formData },
						update: (store, { data: { update_dimension } }) => {
							// const pervious_record = store.readQuery({
							// 	query: GET_DIMENSIONS,
							// 	variables: {
							// 		page: current_page.value,
							// 		limit: 10,
							// 	},
							// }) as Record<any, any>;
							// store.writeQuery({
							// 	query: GET_DIMENSIONS,
							// 	variables: {
							// 		page: current_page.value,
							// 		limit: 10,
							// 	},
							// 	data: {
							// 		dimensions: {
							// 			...pervious_record.dimensions,
							// 		},
							// 		variables: {
							// 			id: update_dimension.id,
							// 		},
							// 	},
							// });
							modal.value.hide();
							loading.value = false;
							Notify.success(`${i18n.t("message.RECORD_UPDATED_SUCCESSFULLY")}`);
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
			current_page.value = 1;
			editDimensionForm.value?.resetFields();
		};

		// Emitter To Open Model
		emitter.on("editDimensionAction", (dimension: Record<any, any>) => {
			resetForm();
			current_page.value = dimension.current_page;
			editDimensionData.value.id = dimension.id;
			editDimensionData.value.name = dimension.name;
			editDimensionData.value.measurement = dimension.measurement;
			modal.value = new Modal(document.getElementById("modal_update_dimension")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			editDimensionData,
			editDimensionForm,
			measurements,
			submitHandlerForm,
		};
	},
});
